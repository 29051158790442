<template>
  <div style="display: grid;">
    <div :style="getElementStyle">

      <el-row v-if="isActive || isDataTableField">
        <el-col :span="3">
          <span
            class="setting-icon"
            @click="openSettings"
          >
            <i class="el-icon-s-tools" /> </span>
        </el-col>
      </el-row>

      <el-row align="middle" style="display: grid;">
        <el-col v-if="!isInDataTable" :span="showLabel && !isDefalutPosq ? 4 : data.description ? 12 : 24" style="white-space: nowrap;">
          <label for="text">{{ data.label }}</label>
          <span
            v-if="data.validations.required"
            style="color: red; font-weight: bold"
          >
            *
          </span>
          <i
        class="el-icon-copy-document " v-if="!isDataTableField" @click="copyDocument" ></i>
          <span>
          <el-popover
            v-if="data.description"
            placement="top-start"
            trigger="hover"
            :content="data.description">
            <i class="el-icon-info" slot="reference"></i>
          </el-popover>
        </span>
        </el-col>
        <!-- <el-col :span="showLabel && isDefalutPosq ? 4 : 0">
          <span
            class="info-right"
            v-if="data.description"
          >
            <i
              class="el-icon-info"
              :title="data.description"
            />
          </span>
        </el-col> -->

        <div
          class="icon-block"
          :style="getStyle"
        >
          <div class="icon avatar-uploader">
            <el-col :span="showLabel && !isDefalutPosq ? 12 : 24">
              <img
                :src="data.source || data.default_value"
                v-if="
                  data.filled_by == 'SENDER' &&
                  (data.source || data.default_value)
                "
                class="sign_img"
                alt="signature"
              />
              <div v-if="data.filled_by !== 'SENDER'">
                signature
                <i class="el-icon-edit" />
              </div>
            </el-col>
          </div>
        </div>
      </el-row>
    </div>

  </div>
</template>

<script>
export default {
  name: "ESignatureView",
  components: {},
  props: ["data", "value", "isActive", "isDataTableField","isInDataTable"],
  computed: {
    getStyle() {
      return `height:${this.data.height - 30}px`;
    },
    getElementStyle() {
      let borderStyle = "";
      if (this.data.styles) {
        let type = this.data.styles.border_type
          ? this.data.styles.border_type
          : "solid";
        let size = this.data.styles.border_size
          ? this.data.styles.border_size + "px"
          : "0px";
        let color = this.data.styles.border_color
          ? this.data.styles.border_color
          : "";
        let font_size =
          this.data.styles &&
          this.data.styles.font &&
          this.data.styles.font.font_size
            ? this.data.styles.font.font_size
            : 14;
        let bold =
          this.data.styles &&
          this.data.styles.font &&
          (this.data.styles.font.style == 1 || this.data.styles.font.style == 3)
            ? "bold"
            : "";
        let italic =
          this.data.styles &&
          this.data.styles.font &&
          (this.data.styles.font.style == 2 || this.data.styles.font.style == 3)
            ? "italic"
            : "";

        borderStyle = "border:" + type + " " + " " + size + " " + color;

        borderStyle += ";";
        borderStyle += this.data.styles.background
          ? `background: ${this.data.styles.background}`
          : "";
        borderStyle += ";";

        borderStyle += this.data.styles.label_color
          ? `color: ${this.data.styles.label_color}`
          : "";
        borderStyle += ";";
        borderStyle += "font-size:" + font_size + "px;";
        if (bold) {
          borderStyle += "font-weight:" + bold + ";";
        }
        if (italic) {
          borderStyle += "font-style:" + italic + ";";
        }
      }
      return borderStyle;
    },
    isDefalutPosq() {
      if (this.data.styles && this.data.styles.labelStyle == "right") {
        return false;
      } else {
        return true;
      }
    }
  },
  data() {
    return {
      validations: [],
      isDefalutPos: true,
      showLabel: true
    };
  },
  mounted() {
    this.options = this.data.options || [];
    if (this.data.styles && this.data.styles.labelStyle) {
      if (this.data.styles.labelStyle == "hide") {
        this.showLabel = false;
      }
      // if (this.data.styles.labelStyle == "right") {
      //   this.isDefalutPos = false;
      // }
    }
  },
  // method
  methods: {
    copyDocument() {
    this.$emit("copy-document", this.data.key);
  },
    openSettings() {
      this.$emit("settings");
    }
  }
};
</script>

<style lang="scss" scoped >
.avatar-uploader {
  height: inherit !important;
  justify-content: left !important;
}

.sign_img {
  height: inherit !important;
  width: 150%;
}
.info-right {
  margin-left: 100px;
}
</style>